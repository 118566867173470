<template>
  <div v-if="nextLevel" class="progress-content">
    <div class="grade-dots">
      <span v-for="n in totalChecks" :key="n" :class="getDotClass(n)"></span>
    </div>
    <div
      v-if="totalChecks !== totalPassingChecks"
      class="remaining-checks-message"
    >
      {{ totalChecks - totalPassingChecks }}
      {{ pluralizedCheck }} to reach
      <span class="next-level">{{ nextLevelName }}</span>
    </div>
  </div>
  <div v-else class="remaining-checks-message max-level">
    All checks passing 🎉
  </div>
</template>

<script>
import { COLORS } from "@/shared/colors.js";
import pluralize from "pluralize";

export default {
  props: {
    nextLevel: {
      required: false,
      type: Object,
      default: null,
      validator: (nextLevel) => {
        return (
          typeof nextLevel === "object" &&
          typeof nextLevel.level === "object" &&
          typeof nextLevel.checkResults === "object" &&
          typeof nextLevel.checkResults.totalCount === "number" &&
          typeof nextLevel.failingCheckResults === "object" &&
          typeof nextLevel.failingCheckResults.totalCount === "number"
        );
      },
    },
  },

  computed: {
    colorFailing() {
      return COLORS.midGrey;
    },
    colorPassing() {
      return COLORS.green;
    },
    colorRemainingChecksMessage() {
      return COLORS.darkGrey;
    },
    pluralizedCheck() {
      return pluralize("check", this.totalChecks - this.totalPassingChecks);
    },
    nextLevelName() {
      return this.nextLevel.level.name || "the next level";
    },
    totalChecks() {
      return this.nextLevel.checkResults.totalCount;
    },
    totalPassingChecks() {
      return this.totalChecks - this.nextLevel.failingCheckResults.totalCount;
    },
  },

  methods: {
    getDotClass(dotNumber) {
      return dotNumber > this.totalPassingChecks
        ? "grade-dot"
        : "grade-dot grade-dot-passed";
    },
  },
};
</script>

<style scoped lang="scss">
.grade-dot {
  display: inline-block;
  background-color: v-bind(colorFailing);
  margin-right: 2px;
  height: 4px;
  width: 4px;
  &.grade-dot-passed {
    background-color: v-bind(colorPassing);
  }
}

.remaining-checks-message {
  color: v-bind(colorRemainingChecksMessage);
  .next-level {
    font-weight: 500;
  }
}

.max-level {
  padding-top: 8px;
}

.progress-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
</style>
