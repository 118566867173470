<template>
  <a-alert
    v-if="!collapsed"
    :type="type"
    :class="{ square: square }"
    :closable="dismissible"
    :show-icon="showIcon"
    @close="onClose"
  >
    <template slot="message">
      <slot name="message">
        {{ message }}
      </slot>
    </template>
    <template slot="description">
      <slot name="description">
        {{ description }}
      </slot>
    </template>
  </a-alert>
</template>

<script>
import { BannerTypes } from "@/shared/banner_types.js";
import moment from "moment";

function parseJson(value) {
  try {
    return JSON.parse(value);
  } catch (error) {
    return {};
  }
}

export default {
  props: {
    type: {
      type: String,
      default: "info",
      required: false,
      validator(type_val) {
        return BannerTypes.includes(type_val);
      },
    },
    message: {
      type: String,
      required: false,
      default: "",
    },
    description: {
      type: String,
      required: false,
      default: "",
    },
    square: {
      type: Boolean,
      required: false,
      default: false,
    },
    expiryReference: {
      type: String,
      required: false,
      default: undefined,
    },
    dismissible: {
      type: Boolean,
      required: false,
      default: true,
    },
    ttl: {
      type: Number,
      required: false,
      default: undefined,
    },
    showIcon: {
      type: Boolean,
      required: false,
      default: true,
    },
    messageOnClose: {
      type: String,
      required: false,
      default: undefined,
    },
    messageOnCloseDuration: {
      type: Number,
      default: 5,
    },
    useLocalStorage: {
      type: Boolean,
      default: false,
    },
    useSessionStorage: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    if (this.expiryReference && this.useLocalStorage) {
      return {
        collapsed: this.getCollapsedStateFromStorage(),
      };
    } else if (this.expiryReference && this.useSessionStorage) {
      return {
        collapsed: this.getCollapsedStateFromStorage(),
      };
    } else {
      return {
        collapsed: false,
      };
    }
  },

  methods: {
    onClose() {
      if (this.messageOnClose) {
        this.$message.info(this.messageOnClose, this.messageOnCloseDuration);
      }

      this.dismiss();
    },
    dismiss() {
      this.collapsed = true;

      if (this.useLocalStorage || this.useSessionStorage) {
        const expiryTTL = this.ttl
          ? moment().add(this.ttl, "seconds").unix()
          : 0;
        const savedState = {
          expiry: expiryTTL,
        };

        this.setStorageItem(this.expiryReference, JSON.stringify(savedState));
      }
    },
    getCollapsedStateFromStorage() {
      if (this.dismissible) {
        const now = moment().unix();
        const bannerTTL = this.getStorageItem(this.expiryReference);

        if (!bannerTTL) {
          return false;
        } else if (now > parseJson(bannerTTL).expiry) {
          // If the item is expired, delete the item from storage
          // before returning false because we should ignore this item
          this.removeStorageItem(this.expiryReference);

          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    setStorageItem(key, value) {
      this.useLocalStorage
        ? localStorage.setItem(key, value)
        : sessionStorage.setItem(key, value);
    },
    getStorageItem(key) {
      return this.useLocalStorage
        ? localStorage.getItem(key)
        : sessionStorage.getItem(key);
    },
    removeStorageItem(key) {
      this.useLocalStorage
        ? localStorage.removeItem(key)
        : sessionStorage.removeItem(key);
    },
  },
};
</script>

<style scoped>
.ant-alert-with-description.square {
  border-radius: 0;
}
</style>
