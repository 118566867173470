<template>
  <span :style="listStyle">
    <template v-for="(item, i) in items">
      <slot v-if="showItem(i)" name="item" :slotProps="{ item, collapsed }" />
      <br v-if="!collapsed" :key="'br-' + i" />
    </template>

    <slot name="button" :slotProps="{ items, numPeeking, buttonText }">
      <a-button
        v-if="items.length > numPeeking"
        size="small"
        class="collapsedButton"
        :class="buttonClass"
        @click="onButtonClicked"
        >{{ buttonText }}</a-button
      >
    </slot>
  </span>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
    // The number of items shown when items are collapsed
    numPeeking: {
      type: Number,
      default: 1,
    },
    listDirectionVertical: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      collapsed: true,
    };
  },

  computed: {
    buttonText() {
      if (this.collapsed) {
        return `+ ${this.items.length - this.numPeeking} more`;
      } else {
        return "- Collapse";
      }
    },
    buttonClass() {
      return { expanded: !this.collapsed };
    },
    listStyle() {
      if (this.listDirectionVertical && this.collapsed) {
        return {
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        };
      } else {
        return {
          display: "block",
        };
      }
    },
  },

  methods: {
    showItem(i) {
      if (this.collapsed) {
        return i < this.numPeeking;
      } else {
        return true;
      }
    },
    onButtonClicked() {
      this.collapsed = !this.collapsed;
    },
  },
};
</script>

<style scoped>
.collapsedButton.expanded {
  margin-top: 8px;
}
:deep(.tag) {
  cursor: default;
  margin-right: 0;
  &::after {
    box-shadow: none !important; /* I can't find a tighter selector due to the ant animation styles so I'm forced to use !important */
  }
}
</style>
