<template>
  <a-card>
    <h3 v-if="isEmptySystemSearchResult" class="hint">
      <p>
        We couldn't find any results matching "<b>{{ searchTerm }}</b
        >".
        <br />
        Please try using a different search term.
      </p>
    </h3>
    <template v-else>
      <h3>
        {{ headerText }}
      </h3>
      <SystemsSearchTable :searchTerm="searchTerm" />
    </template>
  </a-card>
</template>

<script>
import { mapState } from "vuex";
import pluralize from "pluralize";
import SystemsSearchTable from "./SystemsSearchTable.vue";

export default {
  components: {
    SystemsSearchTable,
  },

  props: {
    searchTerm: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapState({
      isFetchingSearchResults: (state) =>
        state.systemsSearch.isFetchingSearchResults,
      filteredSearchCount: (state) => state.systemsSearch.filteredCount,
    }),
    isEmptySystemSearchResult() {
      return this.filteredSearchCount === 0 && !this.isFetchingSearchResults;
    },
    headerText() {
      const count = this.filteredSearchCount;

      return `${count} ${pluralize("System", count)}`;
    },
  },
};
</script>
