<template>
  <a-card>
    <h3 v-if="isEmptyDocumentSearchResult" class="hint">
      <p>
        We couldn't find any results matching "<b>{{ searchTerm }}</b
        >".<br />
        To improve your search results, try searching keywords in the content of
        your Tech Docs.
      </p>
    </h3>
    <template v-else>
      <h3 style="margin-left: 6px">
        {{ filteredDocumentSearchCount }}
        {{ pluralize("Document", filteredDocumentSearchCount) }}
      </h3>
      <DocumentSearchResultList
        :documents="documentSearchResults"
        :loading="isFetchingDocumentSearchResults"
        :itemsPerPage="documentPaginationState.itemsPerPage"
        :totalCount="filteredDocumentSearchCount"
        @fetchMoreAttachedResources="handleFetchMoreAttachedResources"
        @pageChange="handleDocumentsPageChange"
      />
    </template>
  </a-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import pluralize from "pluralize";

import DocumentSearchResultList from "./DocumentSearchResultList.vue";

export default {
  components: {
    DocumentSearchResultList,
  },

  props: {
    urls: {
      type: Object,
      required: true,
    },
    searchTerm: {
      type: String,
      required: true,
    },
  },

  data() {
    return {};
  },

  computed: {
    ...mapState({
      documentSearchResults: (state) => state.documentsSearch.searchResults,
      isFetchingDocumentSearchResults: (state) =>
        state.documentsSearch.isFetchingSearchResults,
      filteredDocumentSearchCount: (state) =>
        state.documentsSearch.filteredCount,
      errorsFetchingDocumentSearchResults: (state) =>
        state.documentsSearch.errorsFetchingSearchResults,
      documentPaginationState: (state) => state.documentsSearch.pagination,
    }),
    isEmptyDocumentSearchResult() {
      return (
        this.documentSearchResults.length == 0 &&
        !this.isFetchingDocumentSearchResults
      );
    },
  },

  mounted() {
    this.$store.watch(
      (state) => state.documentsSearch.errorsFetchingSearchResults,
      (errors) => {
        if (errors && errors.length) {
          this.$message.error(errors[0].message, 10);
        }
      },
    );
  },

  methods: {
    ...mapActions({
      updateDocumentData: "documentsSearch/updateDocumentData",
      fetchAttachedResourcesForDocument:
        "documentsSearch/fetchAttachedResourcesForDocument",
    }),
    handleFetchMoreAttachedResources(documentId) {
      const document = this.documentSearchResults.find(
        (doc) => doc.id === documentId,
      );

      let cursor = document.attachedResources.cursor;

      // cursor is decorated only when user first asks for more services, otherwise we have 10 results
      if (!cursor) {
        cursor = btoa(10);
      }

      this.fetchAttachedResourcesForDocument({
        documentId: document.id,
        first: 100,
        after: cursor,
      });
    },
    handleDocumentsPageChange({ page }) {
      this.updateDocumentData({
        queryParams: {
          searchTerm: this.searchTerm,
          pagination: { current: page },
        },
      });
    },
    pluralize,
  },
};
</script>
