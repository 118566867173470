<template>
  <a-table
    v-if="loading || showEmpty || data.length"
    :columns="columnsWithScopedSlots"
    :dataSource="data"
    :rowKey="(record) => record.id"
    :loading="loading"
    :pagination="tablePagination"
    :expandIconAsCell="expandIconAsCell"
    :expandedRowKeys="expandedRowKeys"
    :expandIconColumnIndex="expandIconColumnIndex"
    :indentSize="indentSize"
    @change="handleTableChange"
    @expandedRowsChange="handleExpandedRowsChange"
  >
    <template
      v-for="(slot, index) in COLUMN_SLOTS"
      v-slot:[slot.slotName]="props"
    >
      <component v-bind="slot.props(props)" :is="slot.component" :key="index" />
    </template>
    <template v-for="(_, slot) in $scopedSlots" v-slot:[slot]="props">
      <slot v-bind="props" :name="slot" />
    </template>
  </a-table>
</template>

<script>
import { table } from "@/mixins/table.jsx";
import { cleanSearchQuery } from "@/shared/search_helper.js";
import {
  COLUMN_SLOTS,
  DATA_TYPE_SLOT_MAPPING,
  RESOURCE_TYPES,
} from "@/shared/table/columns_helper.js";
import { DEFAULT_SORTED_INFO } from "@/shared/table_helper.js";

export default {
  mixins: [table],

  props: {
    columns: {
      type: Array,
      required: true,
      validator: (columns) => {
        let isValidColumns = true;

        columns.forEach((column) => {
          isValidColumns =
            isValidColumns &&
            typeof column === "object" &&
            typeof column.dataType === "string";
        });

        return isValidColumns;
      },
    },
    indentSize: {
      type: Number,
      required: false,
      default: undefined,
    },
    data: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
    },
    totalTableCount: {
      type: Number,
      required: true,
    },
    filterState: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    paginationState: {
      type: Object,
      required: true,
      validator: (paginationState) => {
        return (
          typeof paginationState === "object" &&
          typeof paginationState.currentPage === "number" &&
          typeof paginationState.itemsPerPage === "number"
        );
      },
    },
    sortState: {
      type: Object,
      required: true,
      validator: (sortState) => {
        return (
          typeof sortState === "object" &&
          (sortState.direction === undefined ||
            (typeof sortState.key === "string" &&
              typeof sortState.direction === "string"))
        );
      },
    },
    tableName: {
      // used to override the computed property `namespace` in table.js
      type: String,
      required: false,
      default: undefined,
    },
    defaultSort: {
      type: Object,
      required: false,
      default: () => ({ ...DEFAULT_SORTED_INFO }),
      validator: (defaultSort) => {
        return (
          typeof defaultSort === "object" &&
          typeof defaultSort.columnKey === "string" &&
          typeof defaultSort.order === "string"
        );
      },
    },
    expandIconAsCell: {
      type: Boolean,
      required: false,
    },
    expandedRowKeys: {
      type: Array,
      default: undefined,
    },
    expandIconColumnIndex: {
      type: Number,
      default: undefined,
    },
    showEmpty: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      DATA_TYPE_SLOT_MAPPING,
      COLUMN_SLOTS,
      RESOURCE_TYPES,
      DEFAULT_SORTED_INFO,
    };
  },

  computed: {
    tablePagination() {
      return this.loading ? false : this.pagination;
    },
    namespace() {
      return this.tableName;
    },
    columnsWithScopedSlots() {
      const columnsWithScopedSlots = Array.from(this.columns);

      for (let i = 0; i < this.columns.length; i++) {
        if (this.columns[i].scopedSlots) {
          continue; // if a scope is already provided, do not override it
        }

        const columnDataType = this.columns[i].dataType;
        const columnSlot = this.DATA_TYPE_SLOT_MAPPING[columnDataType];

        if (columnSlot) {
          columnsWithScopedSlots[i].scopedSlots = { customRender: columnSlot };
        } else if (
          this.columns[i].key === "name" ||
          this.RESOURCE_TYPES.includes(columnDataType)
        ) {
          columnsWithScopedSlots[i].scopedSlots = { customRender: "name" };
        }
      }

      return columnsWithScopedSlots;
    },
    initialSort() {
      return this.defaultSort;
    },
  },

  watch: {
    filterState(newFilterState) {
      const queryParams = {
        pagination: { current: 1 },
        searchTerm: cleanSearchQuery(this.searchQuery),
        // Sorters have different keys than the internal sort state
        sorter: {
          columnKey: this.sortState.key,
          order: this.sortState.direction,
        },
        filters: newFilterState,
      };

      this.handleUpdateData(queryParams);
    },
  },

  methods: {
    updateTableData(queryParams) {
      this.$emit("updateTableData", queryParams);
    },
    handleExpandedRowsChange(expandedRows) {
      this.$emit("expandedRowsChange", expandedRows);
    },
  },
};
</script>
