<template>
  <div>
    <div>
      <h3>
        {{ isFetchingSuggestions ? "" : filteredSuggestionsCount }} Service
        {{
          filteredSuggestionsCount === 1 ? "Recommendation" : "Recommendations"
        }}
      </h3>
      <p>
        We detected new service information that's not yet in the catalog. Help
        drive service ownership by confirming the recommendations below.
      </p>
    </div>

    <div
      v-if="teamIds.length === 0"
      style="margin: auto; text-align: center"
      class="no-teams"
    >
      <img
        src="/status_images/no_team_members.svg"
        class="call-to-action-image"
      />
      <p>
        <b>You're not on any teams.</b>
        <br />
        Join a team to get team-specific service recommendations.
      </p>
    </div>

    <span
      v-else-if="isFetchingSuggestions"
      style="display: flex; justify-content: center"
    >
      <SpinningLogo />
    </span>

    <div v-else-if="filteredSuggestionsCount > 0 || busy">
      <a-card
        v-for="(suggestion, index) in suggestions"
        :key="index"
        style="padding: 0; margin-bottom: 8px"
      >
        <div class="suggestion-content">
          <div class="suggestion-text">
            <h3 v-if="suggestion.action.type === 'create_service'">
              Register '{{ suggestion.name }}' as a new service
            </h3>
            <h3 v-else>
              Merge into '{{ pluckAction(suggestion, "serviceName") }}' service
            </h3>

            <p>
              We discovered
              <b v-if="suggestion.action.type === 'create_service'">{{
                pluckAction(suggestion, "serviceName")
              }}</b>
              <span v-else>
                <b>{{ suggestion.aliases[0] }}</b>
                {{
                  suggestion.aliases.length == 1
                    ? ""
                    : ` and +${suggestion.aliases.length - 1} more aliases`
                }}
              </span>
              from
              <a :href="suggestion.sources.edges[0].node.url" target="_blank">{{
                capitalize(suggestion.sources.edges[0].node.type)
              }}</a
              >{{
                suggestion.sources.edges.length == 1
                  ? ""
                  : ` and +${suggestion.sources.edges.length - 1}
              more sources`
              }}. {{ belongsToText(suggestion) }}
              <a-tooltip v-if="usingSuggestedOwner(suggestion)">
                <template slot="title">
                  <p>
                    The following users contributed to this repository recently:
                  </p>
                  <ul>
                    <li
                      v-for="c in getContributors(suggestion)"
                      :key="c.user.id"
                    >
                      <a :href="c.user.htmlUrl">{{ c.user.name }}</a
                      >: {{ c.contributionCount }}
                      {{ c.contributionCount === 1 ? "time" : "times" }}
                    </li>
                  </ul>
                </template>
                <OpsIcon type="ai_sparkle" :style="{ paddingRight: '2px' }" />
                <a :href="ownerHref(suggestion)" class="owner-link">{{
                  ownerName(suggestion)
                }}</a>
              </a-tooltip>
              <a v-else :href="ownerHref(suggestion)" class="owner-link">{{
                ownerName(suggestion)
              }}</a
              >.
              <br />
              <span v-if="suggestion.action.type === 'create_service'"
                >Accept to register it as a new service in your catalog.</span
              >
              <span v-else
                >Merge these aliases and any associated service data into
                <span v-html="serviceLink(suggestion)"></span>.</span
              >
            </p>
          </div>
          <div class="suggestion-actions">
            <a-popconfirm
              placement="bottomLeft"
              :okText="
                suggestion.action.type === 'create_service' ? 'Accept' : 'Merge'
              "
              cancelText="Cancel"
              arrowPointAtCenter
              @confirm="handleAccept(suggestion)"
            >
              <template slot="title">
                <div
                  v-if="suggestion.action.type === 'create_service'"
                  class="popconfirm-text"
                >
                  <p>Accept recommendation?</p>
                  <p>
                    <b>{{ pluckAction(suggestion, "serviceName") }}</b>
                    will be added to the catalog, with
                    <b>{{
                      ownerName(suggestion) ? ownerName(suggestion) : "no team"
                    }}</b>
                    as the assigned owner.
                  </p>
                </div>
                <div v-else class="popconfirm-text">
                  <p>Merge recommendation?</p>
                  <p>
                    The following will be merged into
                    <span v-html="serviceLink(suggestion)"></span>:
                  </p>
                  <ul>
                    <li
                      v-for="(alias, aliasIndex) in suggestion.aliases"
                      :key="aliasIndex"
                    >
                      <b>{{ alias }}</b>
                    </li>
                  </ul>
                </div>
              </template>
              <a-button
                v-if="suggestion.action.type === 'create_service'"
                :disabled="busy"
                type="primary"
                icon="check"
                class="accept-button"
              >
                Accept
              </a-button>
              <a-button
                v-else
                :disabled="busy"
                type="primary"
                icon="check"
                class="accept-button"
              >
                Merge
              </a-button>
            </a-popconfirm>
            <a-popconfirm
              v-if="suggestion.action.type === 'create_service'"
              class="reassign-popconfirm"
              placement="bottom"
              okText="Update suggested team"
              cancelText="I don't know"
              arrowPointAtCenter
              :disabled="busy"
              @confirm="handleAssignOwner(suggestion)"
              @cancel="handleAssignOwner(suggestion, null)"
            >
              <template slot="title">
                <div class="popconfirm-text">
                  <p>
                    Do you know which team owns
                    <b>{{ pluckAction(suggestion, "serviceName") }}</b
                    >?
                  </p>
                  <Selector
                    namespace="teams"
                    stateList="teams"
                    loadingState="isFetchingTeams"
                    fetchMethod="fetchTeams"
                    placeholder="Select a Team"
                    :defaultOption="null"
                    valueKey="id"
                    style="width: 100%; margin-bottom: 4px"
                    @select="handleNewOwnerSelected"
                  />

                  <p style="color: #8c8c8c; margin-bottom: 0px">
                    The selected team will be assigned as the suggested owner.
                  </p>
                </div>
              </template>
              <a-button class="reject-button">
                This is not my service
              </a-button>
            </a-popconfirm>
            <a-button
              class="edit-button"
              :disabled="isAttachHistoricalEvents(suggestion)"
            >
              <a :href="editLink(suggestion)"
                >Review & Edit
                <a-icon type="export" />
              </a>
            </a-button>
          </div>
        </div>
      </a-card>

      <div
        v-if="filteredSuggestionsCount > 3"
        style="margin-top: 16px"
        class="link-to-detected-services"
      >
        <a-button>
          <a :href="filteredRecommendationsLink">
            Review all {{ filteredSuggestionsCount }}
            {{
              filteredSuggestionsCount == 1
                ? "recommendation"
                : "recommendations"
            }}
            on Detected Services
          </a></a-button
        >
      </div>
    </div>

    <EmptyState v-else>
      <img
        slot="image"
        src="/status_images/up_to_date.svg"
        class="call-to-action-image"
      />
      <p
        slot="text"
        style="margin-bottom: 0px"
        v-html="noPendingSuggestionsText"
      />
    </EmptyState>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import EmptyState from "@/components/molecules/EmptyState.vue";
import SpinningLogo from "@/components/SpinningLogo.vue";
import OpsIcon from "@/components/atoms/OpsIcon.vue";
import Selector from "@/components/Selector.vue";
import serviceSuggestions from "@/modules/serviceSuggestions/index.js";
import { get, capitalize } from "lodash";
import { featureFlags } from "@/mixins/featureFlags.js";
import teams from "@/modules/teams/index.js";
import {
  RECEIVE_ACTION_SUGGESTIONS_RESULTS,
  RECEIVE_ACTION_SUGGESTIONS_ERROR,
  RECEIVE_UPDATE_SUGGESTION_SUCCESS,
  RECEIVE_UPDATE_SUGGESTION_ERROR,
} from "@/modules/serviceSuggestions/mutation_types.js";
import { parseObjectToUrlParams } from "@/plugins/url-persistence/url-helper.js";

export default {
  name: "SuggestionsCard",

  components: {
    EmptyState,
    SpinningLogo,
    OpsIcon,
    Selector,
  },

  mixins: [featureFlags],
  inject: ["routes"],

  props: {
    teamIds: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      showGroupedSuggestions: this.hasFeatureFlag("suggestion_groups"),
      queryParams: {
        teamIds: this.teamIds,
        pagination: { pageSize: 3 },
        sorter: { columnKey: "suggestion_sources_count", order: "descend" },
      },
      ownerToAssign: null,
    };
  },

  computed: {
    ...mapState({
      teams: (state) => state.teams.teams,
      // Fetching Suggestions
      suggestions: (state) => state.serviceSuggestionsCard.suggestions,
      totalSuggestions: (state) =>
        state.serviceSuggestionsCard.totalSuggestions,
      suggestionActions: (state) =>
        state.serviceSuggestionsCard.suggestionActions,
      isFetchingSuggestions: (state) =>
        state.serviceSuggestionsCard.isFetchingSuggestions,
      filteredSuggestionsCount: (state) =>
        state.serviceSuggestionsCard.filteredSuggestionsCount,
      errorsFetchingSuggestions: (state) =>
        state.serviceSuggestionsCard.errorsFetchingSuggestions,
      // Ignore Suggestions
      isUpdatingSuggestion: (state) =>
        state.serviceSuggestionsCard.isUpdatingSuggestion,
      notIgnoredServiceSuggestions: (state) =>
        state.serviceSuggestionsCard.notIgnoredSuggestions,
      updateIgnoreValueSuccess: (state) =>
        state.serviceSuggestionsCard.updateIgnoreValueSuccess,
      updatedIgnoreValue: (state) =>
        state.serviceSuggestionsCard.updatedIgnoreValue,
      errorsSettingIgnoredStatus: (state) =>
        state.serviceSuggestionsCard.errorsSettingIgnoredStatus,
      // Action Suggestions
      isActioningSuggestions: (state) =>
        state.serviceSuggestionsCard.isActioningSuggestions,
      actionedResults: (state) => state.serviceSuggestionsCard.actionedResults,
      actionSuggestionErrors: (state) =>
        state.serviceSuggestionsCard.actionSuggestionErrors,
    }),
    noPendingSuggestionsText() {
      return "🎉 <b>You're up to date.</b> 🎉<br/>Recommendations will appear here when we find something new for your team.";
    },
    busy() {
      return (
        this.isActioningSuggestions ||
        this.isFetchingSuggestions ||
        this.isUpdatingSuggestion
      );
    },
    filteredRecommendationsLink() {
      // `routes` param doesn't format array param properly, so we manually append parsed teamIds
      const teamParams = parseObjectToUrlParams({ teamIds: this.teamIds });

      return `${this.routes.recommendations_path()}${teamParams}`;
    },
  },

  created() {
    this.$store.registerModuleOnce("teams", teams);
    this.$store.registerModuleOnce(
      "serviceSuggestionsCard",
      serviceSuggestions,
    );
    this.fetchSuggestions({
      queryParams: this.queryParams,
      showGroupedSuggestions: this.showGroupedSuggestions,
    });
  },

  mounted() {
    this.$store.subscribe(this.onStoreChanged);
  },

  methods: {
    ...mapActions({
      fetchSuggestions: "serviceSuggestionsCard/fetchSuggestions",
      setSuggestionIgnoredValue:
        "serviceSuggestionsCard/setSuggestionIgnoredValue",
      actionSuggestions: "serviceSuggestionsCard/actionSuggestions",
      updateSuggestionInState: "serviceSuggestionsCard/updateSuggestionInState",
      updateSuggestion: "serviceSuggestionsCard/updateSuggestion",
    }),
    pluckAction(suggestion, path = null) {
      const suggestionOverride = get(
        this.suggestionActions,
        `${suggestion.id}.action`,
        {},
      );

      const mergedState = Object.assign(
        {},
        get(suggestion, "action", {}),
        suggestionOverride,
      );
      const action = path ? get(mergedState, path) : mergedState;

      return path === "serviceName" ? this.sanitize(action) : action;
    },
    directOwner(suggestion) {
      return get(suggestion, "action.owner");
    },
    usingSuggestedOwner(suggestion) {
      if (this.directOwner(suggestion)) {
        return false;
      }

      const suggestedOwnerIds = this.suggestedOwners(suggestion).map(
        (o) => o.id,
      );
      const ownerId = this.pluckAction(suggestion, "owner")?.id;

      return suggestedOwnerIds.includes(ownerId);
    },
    getContributors(suggestion) {
      const selectedOwnerId =
        this.ownerWithSuggestedOwnerFallback(suggestion)?.id;

      const selectedSuggestedOwner = suggestion.suggestedOwners.nodes.find(
        (owner) => owner.team.id === selectedOwnerId,
      );

      return selectedSuggestedOwner?.contributors.slice(0, 3);
    },
    handleAccept(suggestion) {
      this.actionSuggestions({
        suggestions: [this.mapModifiedSuggestions(suggestion.id)],
        showGroupedSuggestions: this.showGroupedSuggestions,
      });
    },
    serviceLink(suggestion) {
      const serviceHref = this.pluckAction(suggestion, "serviceHref");

      if (serviceHref) {
        return `<a href="${serviceHref}" target="_blank">${this.pluckAction(
          suggestion,
          "serviceName",
        )}</a>`;
      }
    },
    isAttachHistoricalEvents(suggestion) {
      return suggestion.action.type === "attach_historical_events";
    },
    mapModifiedSuggestions(suggestionId) {
      const suggestion = this.totalSuggestions.find(
        (s) => suggestionId === s.id,
      );

      const action = get(this.suggestionActions, `${suggestion.id}.action`, {});

      return {
        suggestionId: action.id || suggestion.id,
        type: action.type || get(suggestion, "action.type"),
        name: action.serviceName || get(suggestion, "action.serviceName"),
        description:
          action.serviceDescription ??
          get(suggestion, "action.serviceDescription"),
        owner:
          action.owner ??
          get(suggestion, "action.owner") ??
          get(suggestion, "owner") ??
          get(suggestion, "suggestedOwner"),
        serviceId: action.serviceId || get(suggestion, "action.serviceId"),
        suggestionParamsModified:
          action.suggestion_params_modified ||
          get(suggestion, "suggestion_params_modified", false),
        suggestionActionModified:
          action.suggestion_action_modified ||
          get(suggestion, "suggestion_action_modified", false),
      };
    },
    handleActionSuggestionResults({ actioned, notActioned }) {
      if (notActioned.length) {
        this.$message.error("Recommendation was not actioned.");
      } else if (actioned.length > 0) {
        const action = actioned[0];
        const serviceLink = this.$createElement(
          "a",
          { attrs: { href: action.url } },
          action.name,
        );

        this.$notification.success({
          message: this.$createElement("p", [
            "Successfully accepted recommendation.",
          ]),
          description: serviceLink,
          duration: 20,
        });
      }
    },
    handleActionSuggestionError(errors) {
      if (errors?.length) {
        this.$message.error(`Could not action recommendation on ${errors}.`);
      }
    },
    handleAssignOwner(suggestion, ownerToAssign = this.ownerToAssign) {
      this.updateSuggestion({
        suggestion: suggestion.id,
        owner: { id: ownerToAssign },
      });
    },
    handleNewOwnerSelected(owner) {
      this.ownerToAssign = owner.id;
    },
    onStoreChanged({ type, payload }) {
      switch (type) {
        case `serviceSuggestionsCard/${RECEIVE_ACTION_SUGGESTIONS_RESULTS}`:
          this.handleActionSuggestionResults(payload);
          this.fetchSuggestions({
            queryParams: this.queryParams,
            showGroupedSuggestions: this.showGroupedSuggestions,
          });
          break;
        case `serviceSuggestionsCard/${RECEIVE_ACTION_SUGGESTIONS_ERROR}`:
          this.handleActionSuggestionError(payload);
          break;
        case `serviceSuggestionsCard/${RECEIVE_UPDATE_SUGGESTION_SUCCESS}`:
          this.fetchSuggestions({
            queryParams: this.queryParams,
            showGroupedSuggestions: this.showGroupedSuggestions,
          });

          this.$notification.success({
            message: this.$createElement("p", [
              "Successfully updated suggestion ",
              this.$createElement(
                "a",
                { attrs: { href: this.suggestionLink(payload) } },
                payload.name,
              ),
            ]),
            duration: 10,
          });
          break;
        case `serviceSuggestionsCard/${RECEIVE_UPDATE_SUGGESTION_ERROR}`:
          payload.forEach((error) => {
            this.$message.error(
              `Failed to update suggestion: ${error.message}`,
            );
          });
          break;
        default:
          break;
      }
    },
    findTeam(suggestion, teamId) {
      let team = this.teams.find((owner) => owner.id === teamId);

      if (!team) {
        const suggestedOwners = get(suggestion, "suggestedOwners.nodes");

        team = suggestedOwners?.find((owner) => owner.team.id === teamId)?.team;
      }

      return team;
    },
    capitalize,
    suggestedOwners(suggestion) {
      return suggestion.suggestedOwners?.nodes?.map((o) => o?.team) ?? [];
    },
    ownerWithSuggestedOwnerFallback(suggestion) {
      const directOwner = this.directOwner(suggestion);
      const suggestedOwner = get(suggestion, "suggestedOwner");
      const assignedOwner = get(suggestion, "owner");

      if (directOwner && !(suggestion.action.type == "create_service")) {
        return directOwner;
      } else if (this.pluckAction(suggestion, "owner")) {
        return this.pluckAction(suggestion, "owner");
      } else if (assignedOwner) {
        if (!this.pluckAction(suggestion, "owner")) {
          this.updateSuggestionInState({
            id: suggestion.id,
            owner: assignedOwner,
          });
        }

        return assignedOwner;
      } else if (suggestedOwner) {
        if (!this.pluckAction(suggestion, "owner")) {
          this.updateSuggestionInState({
            id: suggestion.id,
            owner: suggestedOwner,
          });
        }

        return suggestedOwner;
      }
    },
    ownerName(suggestion) {
      return this.ownerWithSuggestedOwnerFallback(suggestion)?.name;
    },
    ownerHref(suggestion) {
      return this.ownerWithSuggestedOwnerFallback(suggestion)?.href;
    },
    editLink(suggestion) {
      return `${this.filteredRecommendationsLink}&highlightedSuggestionId=${suggestion.id}`;
    },
    suggestionLink(suggestion) {
      return this.routes.recommendations_path({
        highlightedSuggestionId: suggestion.id,
      });
    },
    belongsToText(suggestion) {
      if (suggestion?.action?.type === "create_service") {
        return "It seems like it might belong to";
      } else {
        return "This belongs to";
      }
    },
  },
};
</script>

<style scoped>
.popconfirm-text {
  max-width: 400px;
  margin-bottom: 8px;
}

emptystate {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.call-to-action-image {
  width: 200px;
}
</style>
